@import url("https://fonts.googleapis.com/css2?family=Lora:ital@1&family=Montserrat:wght@300&display=swap");
:root {
  --title-font: "Montserrat", sans-serif;
  --text-font: "Lora", serif;

  --primaryColor: #e4dfd6;
  --lightPrimaryColor: #e7e3de;

  --navColor5: #dac8b2;
  --navColor4: #b27c6b;
  --navColor3: #424041;
  --navColor2: #88acb2;
  --navColor1: #d2e4e6;

  --shadow: 0 0.25rem 1rem rgba(0, 0, 0, 0.2);
}

html {
  box-sizing: border-box;
  font-size: 62.5%;
  background: var(--primaryColor);
}
body {
  margin: 4rem;
  background-color: whitesmoke;

  min-height: 100vh;
}
.overlay {
  position: fixed;
  z-index: 9;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
  transform: translateX(-100vw);
}
.overlay-slide-right {
  transition: all 0.4s ease-in-out;
  transform: translateX(0);
}
.overlay-slide-left {
  transition: all 0.8s ease-in-out;
  transform: translateX(-100vw);
}

/* slide in animation with delay for each nav item */
.slide-in-1 {
  animation: slide-in 0.4s linear 0.2s both;
}
.slide-in-2 {
  animation: slide-in 0.4s linear 0.4s both;
}
.slide-in-3 {
  animation: slide-in 0.4s linear 0.6s both;
}
.slide-in-4 {
  animation: slide-in 0.4s linear 0.8s both;
}
.slide-in-5 {
  animation: slide-in 0.4s linear 1s both;
}

@keyframes slide-in {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

/* slide out animation with delay for each nav item */
.slide-out-1 {
  animation: slide-out 0.3s linear 0.5s both;
}
.slide-out-2 {
  animation: slide-out 0.3s linear 0.4s both;
}
.slide-out-3 {
  animation: slide-out 0.3s linear 0.3s both;
}
.slide-out-4 {
  animation: slide-out 0.3s linear 0.2s both;
}
.slide-out-5 {
  animation: slide-out 0.3s linear 0.1s both;
}

@keyframes slide-out {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}
/* menu bars */
.menu-bars {
  position: fixed;
  top: 4rem;
  right: 5rem;
  z-index: 10;
  display: inline;
  cursor: pointer;
}
.bar1,
.bar2,
.bar3 {
  width: 35px;
  height: 2px;
  background-color: var(--navColor3);
  margin: 8px 0;
  transition: 0.4s;
}
/* Rotate first bar */
.change .bar1 {
  transform: rotate(-45deg) translate(-7px, 8px);
}

/* Fade out the second bar */
.change .bar2 {
  opacity: 0;
}

/* Rotate last bar */
.change .bar3 {
  transform: rotate(45deg) translate(-6px, -8px);
}

/* Navigation Menu */

nav,
nav ul {
  height: 100vh;
  margin: 0;
  padding: 0;
}
nav ul {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  list-style: none;
}

nav ul li {
  height: 25%;
  overflow: hidden;
}
nav ul li a {
  position: relative;
  top: 45%;
  color: whitesmoke;
  text-transform: uppercase;
  letter-spacing: 0.4rem;
  text-decoration: none;
  display: block;
  text-align: center;
  transition: all 0.3s;
  font-family: var(--title-font);
  font-size: 3rem;
  font-weight: 600;
}
nav li a:hover {
  transform: scale(1.2);
}

nav li:nth-of-type(1) {
  background-color: var(--navColor1);
}
nav li:nth-of-type(2) {
  background-color: var(--navColor2);
}
nav li:nth-of-type(3) {
  background-color: var(--navColor3);
}
nav li:nth-of-type(4) {
  background-color: var(--navColor4);
}
nav li:nth-of-type(5) {
  background-color: var(--navColor5);
}
section {
  width: 100%;
  min-height: 100vh;
  position: relative;
  letter-spacing: 2px;
  color: var(--primaryColor);
}
.home {
  background-image: url("../public/img/background-img.jpg");

  background-size: cover;
  background-position: center center;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.home a {
  text-decoration: none;
  text-transform: uppercase;
  color: var(--navColor3);
  font-family: var(--title-font);
  font-size: 7rem;
  font-weight: 700;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid var(--navColor3);
}
.home h2 {
  font-family: var(--text-font);
  color: var(--navColor4);
  letter-spacing: 2rem;
  font-size: 4rem;
  margin-top: 0.5rem;
}

/* all section titles */
.section-title {
  text-align: center;
  text-transform: uppercase;
  font-family: var(--title-font);
  font-size: 4rem;
  color: var(--navColor3);
}
/* about */
.about-container {
  color: var(--navColor3);
  max-width: 80%;
  margin: 3rem auto;
}
.text-image {
  display: flex;
  justify-content: center;
  align-items: center;
}
.about-text {
  font-size: 2.5rem;
  font-family: var(--text-font);
  flex: 2;
  text-align: justify;
}
.about-image {
  flex: 1;
}
.about-image-img {
  max-width: 100%;
  height: auto;
  object-fit: cover;
  margin-left: 3rem;
  border: 2px solid var(--navColor4);
}
.about-skills {
  margin: 3rem auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 100%;
}
.about-skills h4 {
  font-family: var(--title-font);
  text-transform: uppercase;
  font-size: 3rem;
  margin: 0;
}
.skills {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.skills-list {
  font-family: var(--title-font);
  text-transform: uppercase;
  font-size: 2rem;
  font-weight: 600;
  letter-spacing: 0.25rem;
  list-style: none;
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.bar-container {
  width: 100%;
  background-color: var(--navColor3);
  margin-bottom: 2rem;
}
.skill {
  background-color: var(--navColor2);
  padding: 0.25rem 0 0.25rem 1rem;
}
.html {
  width: 80%;
}
.css {
  width: 80%;
}
.js {
  width: 75%;
}
.react {
  width: 65%;
}
.node {
  width: 60%;
}
.mongo {
  width: 60%;
}
.python {
  width: 50%;
}

/* projects */
.projects-container {
  margin: 3rem auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 80%;
  grid-gap: 4rem;
}
.project {
  /* display: flex;
  flex-direction: column; */
  position: relative;
  transition: all 0.2s;
  height: 100%;
  width: 100%;
  background-color: var(--lightPrimaryColor);
  box-shadow: var(--shadow);
}

.buttons-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: none;
  transition: all 0.2s;
}

.button {
  border: 1px solid var(--on-title);

  background-color: var(--navColor2);
  font-family: var(--title-font);
  font-size: 2rem;
  font-weight: 500;
  text-transform: uppercase;
  text-decoration: none;
  padding: 0.75rem 1.5rem;
  color: whitesmoke;
  margin: 1rem auto;
  text-align: center;
}
.button:hover {
  filter: brightness(1.2);
}
.image-text-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0.5rem;
}
.project-image {
  max-width: 100%;
  height: 90%;
}
.project-title {
  font-family: var(--title-font);
  color: var(--navColor3);
  font-size: 2rem;
  font-weight: 600;
  letter-spacing: 0.5rem;
  margin-top: 1rem;
  text-align: center;
}
.project-title::after {
  content: "";
  display: block;
  height: 3px;
  width: inherit;
  background-color: var(--primaryColor);
}
.description {
  margin: auto 2rem;
  color: var(--navColor3);
  font-family: var(--text-font);
  font-size: 2rem;
  letter-spacing: 0.1rem;
  text-align: justify;
  font-weight: 600;
}

.project:hover .buttons-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 2;
  height: 100%;
  width: 100%;
}
/* Blogs */
.blogs {
  margin-top: 10rem;
}
.blogs-container {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: var(--text-font);
  color: var(--navColor4);
  font-size: 3rem;
}

/* connect */
.connect {
  margin: 3rem auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.connect h1 {
  font-size: 3rem;
  letter-spacing: 0.5rem;
  text-align: center;
  color: var(--navColor4);
  font-family: var(--title-font);
  text-transform: uppercase;
}
.icon-container {
  margin: 4rem auto;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 80%;
}
.icons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.icon {
  font-size: 7rem;
  margin: auto 6rem;
  color: var(--navColor2);
}
.icons p {
  font-family: var(--text-font);
  font-size: 2.5rem;
  margin-top: 0;
  color: var(--navColor3);
}
.icon:hover {
  color: var(--navColor3);
}
/* footer */
footer {
  color: var(--on-title);

  width: 100%;
  font-family: var(--title-font);
  display: flex;
  justify-content: center;
  align-items: center;
}
footer p {
  margin: 1rem auto;
  font-size: 2rem;
}

@media screen and (max-width: 1300px) {
  .about-text {
    font-size: 2rem;
  }
}
@media screen and (max-width: 1150px) {
  .projects-container {
    grid-template-columns: repeat(2, 1fr);
    width: 80%;
  }
}
@media screen and (max-width: 950px) {
  .text-image {
    display: flex;
    flex-direction: column-reverse;
  }
  .about-text {
    flex: unset;
    text-align: justify;
  }
  .about-image {
    flex: unset;
  }
  .about-image-img {
    max-width: 100%;
    height: 50vh;

    margin-left: unset;
  }
}
@media screen and (max-width: 900px) {
  .skills-list {
    font-size: 1.5rem;
  }
}
@media screen and (max-width: 800px) {
  html {
    font-size: 55%;
  }
  .projects-container {
    grid-template-columns: repeat(1, 1fr);
    width: 80%;
  }
  .about-skills {
    margin: 2rem auto;
  }
}
@media screen and (max-width: 600px) {
  html {
    font-size: 50%;
  }
  body {
    margin: 0;
  }
  .skills-list {
    width: 100%;
  }
  .icon {
    font-size: 7rem;
    margin: auto 4rem;
  }
}
@media screen and (max-width: 500px) {
  html {
    font-size: 45%;
  }
  .home a {
    font-size: 6rem;
    font-weight: 700;
    padding-bottom: 0.5rem;
    text-align: center;
  }
  .home h2 {
    font-size: 3rem;
    letter-spacing: 2rem;
    margin-top: 0.5rem;
  }

  .icon {
    font-size: 7rem;
    margin: auto 2.5rem;
  }
}
@media screen and (max-width: 400px) {
  html {
    font-size: 40%;
  }
  .home a {
    font-size: 5rem;
    font-weight: 700;
    padding-bottom: 0.5rem;
    text-align: center;
  }
  .home h2 {
    font-size: 3rem;
    letter-spacing: 2rem;
    margin-top: 0.5rem;
  }
  .icon {
    font-size: 7rem;
    margin: auto 2.5rem;
  }
}
